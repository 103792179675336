@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');

html,
body {
	height: 100%;
}
body {
	margin: 0;
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

app-root {
	min-height: 100%;
	height: fit-content;
	display: block;
}

@layer base {
	@font-face {
		font-family: Selawik;
		font-weight: 300;
		src: url(/assets/fonts/selawkl.ttf);
	}

	@font-face {
		font-family: Selawik;
		font-weight: 400;
		src: url(/assets/fonts/selawksl.ttf);
	}

	@font-face {
		font-family: Selawik;
		font-weight: 500;
		src: url(/assets/fonts/selawk.ttf);
	}

	@font-face {
		font-family: Selawik;
		font-weight: 600;
		src: url(/assets/fonts/selawksb.ttf);
	}

	@font-face {
		font-family: Selawik;
		font-weight: 700;
		src: url(/assets/fonts/selawkb.ttf);
	}
}

.cdk-overlay-pane {
	div.mat-mdc-menu-panel {
		max-width: none;
	}
}

//Fixes issue with Tailwind and Material 15. With new version in future should be able to remove
.mdc-notched-outline__notch {
	border-right: none;
}

button.mat-mdc-mini-fab,
button.mat-mdc-fab,
button.mat-mdc-mini-fab:hover,
button.mat-mdc-fab:hover {
	box-shadow: none;
}


//Removes arrows from number inputs
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.no-wrap-mat-form-field {
	width: 100%;
	.mat-mdc-form-field-subscript-wrapper {
		display: none;
	}

	.mat-mdc-text-field-wrapper {
		height: 30px;
	}

	.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
		padding: 0;
	}

	.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
	.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
		height: 30px;
	}
}

app-main-toolbar {
	.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
		right: 6px;
	}
	.mat-badge-small.mat-badge-above .mat-badge-content {
		top: 0;
	}
}
app-main-toolbar::after {
	height: 4px;
	display: block;
	top: 100%;
	right: 0px;
	left: 0px;
	background: linear-gradient(rgba(9, 30, 66, 0.13) 0px, rgba(9, 30, 66, 0.13) 1px, rgba(9, 30, 66, 0.08) 1px, rgba(9, 30, 66, 0) 4px);
	content: '';
}

app-first-login-reset-password,
app-change-password-request,
app-login {
	height: calc(100vh - 50px);
	display: block;
}


.mat-mdc-dialog-container app-login {
	height: 400px;
}

.mat-mdc-table .mdc-data-table__row {
	@apply h-10 max-h-10;
}

.mat-mdc-table .mat-mdc-header-row {
	@apply h-10 bg-primary-200;
}
.mat-mdc-table .mat-column-def {
	flex: 1;
}

.mdc-data-table__header-cell {
	@apply text-lg border-b-2 border-black;
}

.mat-form-qty-padding .mat-mdc-text-field-wrapper {
	@apply pr-1;
}

.mdc-list-item__primary-text {
	@apply flex flex-col justify-center items-start;
}
.card{
  @apply w-full h-full p-2 shadow border rounded m-auto;
}

.mat-mdc-row:nth-child(even) {
  @apply bg-gray-200;
}

.mat-mdc-table .mdc-data-table__row:nth-child(even):hover {
  @apply bg-gray-300;
}
.mat-mdc-table .mdc-data-table__row:nth-child(odd):hover {
  @apply bg-gray-300;
}

.scroll-table-card{
  @apply h-fit max-h-[40vh] overflow-auto;
}

app-location-list, app-interface-list, app-client-list, app-environment-list, app-service-list, app-endpoint-list {
  @apply absolute top-0 w-full;
}

